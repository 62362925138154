import React, { ReactNode, useCallback } from 'react'

import { FluidObject } from 'gatsby-image'
import Img from 'gatsby-image/withIEPolyfill'
import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { styled } from 'src/ui/theme'
import { HBox, Col } from 'src/ui/layout'
import { Adornments, CustomSliderContainer } from 'src/ui/atoms'
import { Video } from 'src/ui/molecules'
import { Label, Header } from 'src/ui/typography'

import { sliderInMainSettings, TVideoType } from 'src/shared/constants'
import { SwiperSlider } from '../swiper-slider'

const isIOS =
  typeof navigator !== 'undefined'
    ? /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    : false

const Container = styled(CustomSliderContainer)`
  background: ${({ theme }) => theme.colors.white.primary};
  padding: 0 16px;

  .swiper {
    height: 100%;
  }

  .swiper-pagination {
    position: absolute;
    bottom: 54px;
  }
`

const HeadingContainer = styled(Col)<{ height?: number }>`
  display: flex;
  justify-content: flex-end;
  height: ${({ height }) => (height ? height : 269)}px;
  position: relative;

  z-index: 2;

  transition: height 0.3s;
`

const Content = styled.div<{ width?: number; noPointerEvents?: boolean }>`
  position: relative;
  left: -1px;
  padding: 0;
  max-width: ${({ width }) => (width ? width : 96)}%;
  background: ${({ theme }) => theme.colors.white.primary};

  box-shadow: -2px 4px 0 1px #fff;
  ${({ noPointerEvents }) => (noPointerEvents ? 'pointer-event:none;' : '')}
  z-index: 4;
`

const Image = styled(Img)`
  position: absolute !important;
  object-fit: cover;
  right: 0;
  top: 0;
  width: 288px;
  height: 218px;

  z-index: 0;
`

const ImageNative = styled.div<{ src: string }>`
  position: absolute;
  background: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  right: 0;
  top: 0;
  width: 100%;
  height: 95%;
  z-index: 0;
`

const StyledVideo = styled.div<{ height: number }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: ${({ height }) => height}px;
  background: ${({ theme }) => theme.colors.black.bg};

  z-index: 3;
`

const Adornment = styled.div`
  position: absolute;

  right: -6px;
  bottom: 36px;
  z-index: 5;
`

const SliderItem = styled.div`
  width: 288px;
  height: 218px;
`

const Slider = styled(SwiperSlider)`
  position: absolute;
  right: 0;
  top: 0;
  width: 288px;
  height: 218px;
  z-index: 0;
`

type Props = {
  background?: FluidObject | string
  videoId?: string
  videoType?: TVideoType
  title: string
  children: ReactNode
  titleColor?: string
  titleSize?: 'h4' | 'h5'
  titleAs?: 'h1' | 'h2'
  label?: string
  adornment?: boolean
  objectPosition?: string
  textHeight?: number
  textWidth?: number
  noPointerEvents?: boolean
  sliderItems?: {
    image?: FluidObject
  }[]
  pictureLink?: string
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
}

export const Hero = ({
  title,
  background,
  titleColor,
  titleSize = 'h4',
  titleAs,
  label,
  videoId,
  videoType = 'youtube',
  adornment,
  children,
  objectPosition,
  textHeight,
  textWidth,
  noPointerEvents,
  sliderItems,
  pictureLink,
  objectFit,
}: Props) => {
  const [videoHeight, setVideoHeight] = React.useState(218)

  const getMediaContent = useCallback(() => {
    if (sliderItems?.length) {
      return (
        <Slider settings={sliderInMainSettings} customPagination>
          {sliderItems.map(({ image }, index) => (
            <SliderItem>
              <SwiperSlide key={index}>
                <Image fluid={image} objectPosition={objectPosition} />
              </SwiperSlide>
            </SliderItem>
          ))}

          <div className="swiper-pagination"></div>
        </Slider>
      )
    }

    if (background && typeof background === 'object') {
      if (pictureLink) {
        return (
          <a target="_blank" href={pictureLink}>
            <Image
              fluid={background}
              objectPosition={objectPosition}
              objectFit={objectFit}
            />
          </a>
        )
      }
      return (
        <Image
          fluid={background}
          objectPosition={objectPosition}
          objectFit={objectFit}
        />
      )
    }

    if (background && typeof background === 'string') {
      if (pictureLink) {
        return (
          <a target="_blank" href={pictureLink}>
            <ImageNative src={background} />
          </a>
        )
      }

      return <ImageNative src={background} />
    }

    if (videoId) {
      return (
        <StyledVideo height={218}>
          <Video
            onPlay={() => setVideoHeight(292)}
            onPause={() => {
              if (!isIOS) {
                setVideoHeight(218)
              }
            }}
            videoId={videoId}
            type={videoType}
            onReady={() => null}
            height={218}
          />
        </StyledVideo>
      )
    }

    return null
  }, [sliderItems, background, videoId, isIOS, objectPosition])

  return (
    <Container>
      <HeadingContainer height={videoId ? videoHeight : textHeight}>
        {getMediaContent()}

        {adornment && !sliderItems?.length ? (
          <Adornment>
            <Adornments.DashedSquare />
          </Adornment>
        ) : null}

        <Content
          style={{
            paddingRight: 16,
          }}
          width={textWidth}
          noPointerEvents={noPointerEvents}
        >
          <HBox />
          {label ? (
            <>
              <Label weight="bold">{label}</Label>
              <HBox height={8} />
            </>
          ) : null}
          <Header size={titleSize} as={titleAs} color={titleColor}>
            {title}
          </Header>
        </Content>
      </HeadingContainer>
      <Content>{children}</Content>
    </Container>
  )
}
